import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Vermo } from '../../common/styled';
import { ButtonComponent } from '../../common/Button';
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { GeneralDataSelector, GET_ALL_FIXTURE_COMMENTS, getAllComments, useSubscription } from '@chidiahua/vermo-redux';
import { handleUserComment } from '../../../utils/actions/actions';
import { useFormik } from 'formik';
import { desktop } from "../../../theme/fontSize";

interface BanterProps {
  setBanterMatchId: () => void;
  currentMatch: any;
  banterMatchId: string | null;
}

interface ChatBoxProps {
  isCurrentUser: boolean;
}

const BanterModal: React.FC<BanterProps> = ({ banterMatchId, setBanterMatchId, currentMatch }) => {
  const dispatch: ThunkDispatch<any, any, any> = useDispatch();
  const { userCommentLoading, userDetails } = GeneralDataSelector() as any;
  const { data, loading } = useSubscription(GET_ALL_FIXTURE_COMMENTS) as any;

  const scrollAnchorRef = useRef<HTMLDivElement>(null); // Reference for scrolling

  useEffect(() => {
    if (data?.getComments?.length) {
      dispatch(getAllComments(data?.getComments));
    }
  }, [data]);

  const comments = data?.getComments?.data;

  // Effect to scroll to the last comment when modal opens or new comment is added
  useEffect(() => {
    if (scrollAnchorRef.current) {
      scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [comments]); // This runs whenever comments change

  const handleCommentSubmit = async (values: any, { resetForm }: any) => {
    await handleUserComment(dispatch, values, banterMatchId, resetForm);
  };

  // Add banter comment
  const formik = useFormik({
    initialValues: {
      commentableId: "",
      commentableType: "FootballFixtures",
      comment: ""
    },
    onSubmit: handleCommentSubmit
  });

  // Function to check if a string is a URL
  const isUrl = (str: string) => {
    const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return str.match(urlRegex) !== null;
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Vermo.FlexEnd>
          <Vermo.TextSmall onClick={setBanterMatchId}>
            <CloseIcon size={20} />
          </Vermo.TextSmall>
        </Vermo.FlexEnd>
        <Vermo.FlexBetween>
          <Vermo.TextSmall>{currentMatch?.homeTeam}</Vermo.TextSmall>
          <MatchVS>VS</MatchVS>
          <Vermo.TextSmall>{currentMatch?.awayTeam}</Vermo.TextSmall>
        </Vermo.FlexBetween>
      </div>
      <div style={{ overflowY: "scroll", maxHeight: "80vh" }}>
        {loading ? "Loading..." : comments?.map((item: any) => (
          <ChatBox key={item?.id} isCurrentUser={userDetails?.me?.id === item?.user?.id}>
            <Vermo.FlexBetween style={{ gap: "20px" }}>
              <Vermo.TextSmall>{item?.user?.name}</Vermo.TextSmall>
              <span>Fixture:&nbsp;&nbsp;
                <img className="leagueimg" src={item?.fixture?.homeTeam?.logo} alt={item?.fixture?.homeTeam?.name} />  X &nbsp;&nbsp;  
                <img className="leagueimg" src={item?.fixture?.awayTeam?.logo} alt={item?.fixture?.awayTeam?.name} />
              </span>
            </Vermo.FlexBetween>
            <ChatMessage isCurrentUser={userDetails?.me?.id === item?.user?.id}>
              {isUrl(item?.comment) ? (
                <a href={item?.comment} target="_blank" rel="noopener noreferrer">
                  {item?.comment}
                </a>
              ) : (
                item?.comment
              )}
            </ChatMessage>
          </ChatBox>
        ))}
        <div id="scroll-anchor" ref={scrollAnchorRef}></div> {/* Scroll anchor */}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex_class">
          <Vermo.InputMessage
            placeholder="start conversation"
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            style={{ width: "80rem" }}
          />
          <ButtonComponent htmlType="submit" text="Send" style={{ width: "70px" }} loading={userCommentLoading} />
        </div>
      </form>
    </div>
  );
};

export default BanterModal;

const ChatBox = styled.div<ChatBoxProps>`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  justify-content: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.isCurrentUser ? 'right' : 'left')};
  .leagueimg {
    height: clamp(16px, 1.4vw, 25px);
    margin-right: 15px;
  }
`;

const ChatMessage = styled.span<ChatBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  justify-content: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.isCurrentUser ? 'right' : 'left')};
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
`;

const MatchVS = styled.span`
  padding: 10px;
  border-radius: 50%;
  background: ${(props) => props.theme.mutedColorSecondary};
  color: ${(props) => props.theme.textColor};
  font-weight: 600;
  font-size: ${desktop.small};
  text-align: center;
  margin: 0 3%;
`;